import React from 'react';
import styles from './Loading.module.scss';
import logo from '../../assets/images/logo_loading.png'

function Loading() {
  return (
    <div className={styles.container}>
        <img className={styles.logo} src={logo} alt="logo" />
    </div>
  )
}

export default Loading