import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend) // Завантаження перекладів через HTTP
  .use(LanguageDetector) // Автовизначення мови
  .use(initReactI18next) // Інтеграція з React
  .init({
    fallbackLng: 'uk-UA', // Українська мова за замовчуванням
    debug: false, // Вимикаємо debug у продакшені
    interpolation: { escapeValue: false }, // React автоматично екранує значення

    detection: {
      order: ['path', 'localStorage', 'cookie', 'navigator'], // URL має пріоритет
      lookupFromPathIndex: 0, // Дивитися першу частину шляху (`/en`, `/uk`)
      caches: ['localStorage'], // Запам’ятовуємо вибір користувача
    },

    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Завантаження JSON-файлів
    },
  });

export default i18n;
