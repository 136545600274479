import React, { useEffect, lazy, Suspense } from "react";
import { Route, Routes, BrowserRouter, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loading from "./pages/Loading/Loading";
import "./styles/styles.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

const HomePage = lazy(() => import("./pages/HomePage/HomePage"));
const CottagesPage = lazy(() => import("./pages/CottagesPage/CottagesPage"));
const GenplanPage = lazy(() => import("./pages/GenplanPage/GenplanPage"));
const PodyhPage = lazy(() => import("./pages/PodyhPage"));
const NeboPage = lazy(() => import("./pages/NeboPage"));
const SolarOnePage = lazy(() => import("./pages/SolarOnePage"));
const SolarTwoPage = lazy(() => import("./pages/SolarTwoPage"));
const SpokiyPage = lazy(() => import("./pages/SpokiyPage"));
const DyvoPage = lazy(() => import("./pages/DyvoPage"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));

const LanguageWrapper = ({ children }) => {
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const isEnglish = location.pathname.startsWith("/en");
    const newLang = isEnglish ? "en" : "uk";

    if (i18n.language !== newLang) {
      i18n.changeLanguage(newLang);
    }

    document.documentElement.lang = newLang;
    document.documentElement.setAttribute("xml:lang", newLang);
  }, [location.pathname, i18n]);

  return children;
};

const RedirectToCorrectLang = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.startsWith("/uk-UA")) {
      navigate(location.pathname.replace(/^\/uk-UA/, ""), { replace: true });
    }
  }, [location, navigate]);

  return null;
};

const NotFound = () => {
  useEffect(() => {
    document.title = "404 - Page Not Found";

    // Викликаємо сервер, щоб він повернув статус 404
    fetch("/404.html", { method: "HEAD" });
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <NotFoundPage />
    </>
  );
};

const LanguageRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/cottages" element={<CottagesPage />} />
    <Route path="/ganplan" element={<GenplanPage />} />
    <Route path="/cottages/podyh" element={<PodyhPage />} />
    <Route path="/cottages/nebo" element={<NeboPage />} />
    <Route path="/cottages/solar3.1" element={<SolarOnePage />} />
    <Route path="/cottages/solar3.2" element={<SolarTwoPage />} />
    <Route path="/cottages/svitanok" element={<SpokiyPage />} />
    <Route path="/cottages/dyvo" element={<DyvoPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <LanguageWrapper>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/uk-UA/*" element={<RedirectToCorrectLang />} />
              <Route path="/*" element={<LanguageRoutes />} />
              <Route path="/en/*" element={<LanguageRoutes />} />
            </Routes>
          </Suspense>
        </LanguageWrapper>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
